@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  @apply bg-white;
}

.naglowek {
  @apply py-8 mb-8 bg-neutral-200 border-b-4 border-blue-300;
}

.naglowek__logo {
  @apply text-center uppercase text-gray-500 font-semibold text-xs;
}

.stopka {
  @apply mt-16 py-16 bg-neutral-200 text-neutral-700 border-t-4 border-blue-400 flex justify-between;
}

.stopka a {
  @apply text-rose-700 hover:text-rose-900 hover:underline;
}

.nav {
  @apply mb-4;
}
.nav .active a {
  @apply underline font-bold;
}

.nav a {
  @apply block font-medium text-blue-900 hover:text-blue-600;
}

.read-email {
  @apply flex gap-2 items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;
}
